import React from "react"
import { navigate } from "gatsby"

const Blog = () => {
  if (typeof window !== "undefined") {
    navigate("/blog/rubbish-at-st-clere")
  }
  return null
}

export default Blog
